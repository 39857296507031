/* Main Container */
.table-container {
    width: 100%;
    margin: 30px auto;
    padding: 30px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Softer gradient */
    border-radius: 15px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1); /* Enhanced depth */
    color: #333;
    transition: all 0.4s ease-out;
    overflow: auto;
  }
  
  /* Table */
  .universal-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 35px;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  
  /* Table Header */
  .universal-table th {
    padding: 20px 30px;
    background: linear-gradient(135deg, #007dc4, #006bb3);
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 4px solid #ddd;
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1);
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Table Cells */
  .universal-table td {
    padding: 16px 25px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    color: #555;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: 500;
  }
  
  .universal-table td:hover {
    background-color: #f1f1f1;
    transform: translateX(6px); /* Smoother hover effect */
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 15px;
  }
  
  .pagination button {
    padding: 14px 28px;
    margin: 0 10px;
    background-color: #007dc4;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-out, transform 0.2s ease;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
  }
  
  .pagination button:hover {
    background-color: #006bb3;
    transform: scale(1.1); /* Slight zoom effect */
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  }
  
  .page-number {
    padding: 12px 25px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .page-number.active {
    background-color: #007dc4;
    color: white;
  }
  
  .page-number:hover {
    background-color: #f9f9f9;
    transform: translateY(-2px); /* Smooth lifting effect */
  }
  
  .ellipsis {
    padding: 10px 25px;
    cursor: default;
  }
  
  /* Table Header + Sorting */
  .table-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .table-h-c {
    flex: 1;
  }
  
  /* Show Rows Dropdown */
  .show-by.table-h-c {
    display: flex;
    justify-content: flex-end;
  }
  
  .show-by select {
    padding: 12px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    font-size: 15px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .show-by select:hover {
    background-color: #f9f9f9;
    transform: scale(1.05); /* Subtle zoom effect */
  }
  
  .show-by option {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
  }
  
  /* Input Field & Button */
  .input-field button {
    background-color: #007dc4;
    color: white;
    padding: 14px 30px;
    border-radius: 10px;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    transition: background-color 0.3s ease, box-shadow 0.2s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .input-field button:hover {
    background-color: #006bb3;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .input-field input {
    padding: 12px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #333;
    transition: border 0.3s ease;
  }
  
  .input-field input:focus {
    border-color: #007dc4;
    outline: none;
  }
  
  /* Table Edit Icon */
  .table-edit {
    width: 28px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .table-edit:hover {
    transform: scale(1.6);
    color: #006bb3;
  }
  
  .table-view {
    width: 28px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .table-view:hover {
    transform: scale(1.6);
    color: #006bb3;
  }
  
  .table-delete:hover {
    transform: scale(1.6);
    color: rgb(217, 7, 7);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .universal-table th, .universal-table td {
      padding: 14px 20px;
      font-size: 14px;
    }
  
    .pagination button,
    .page-number {
      padding: 10px 18px;
    }
  
    .show-by select {
      font-size: 13px;
      padding: 10px;
    }
  
    .input-field input {
      font-size: 13px;
    }
  }
  
  /* Status Box */
  .status-box {
    display: inline-block;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    text-align: center;
    white-space: nowrap;
    background-color: #ffffff;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .status-box:hover {
    box-shadow: 0 7px 18px rgba(0, 0, 0, 0.15);
  }
  
  .status-documentselected {
    background-color: #a8d0e6;
    color: #2c3e50;
  }
  
  .status-pickup {
    background-color: #ff9f43;
    color: #fff;
  }
  
  .status-login {
    background-color: #34ace0;
    color: #fff;
  }
  
  .status-query {
    background-color: #ff6b81;
    color: #fff;
  }
  
  .status-sanction {
    background-color: #4caf50;
    color: #fff;
  }
  
  .status-disburse {
    background-color: #6c5ce7;
    color: #fff;
  }
  
  .status-cancel {
    background-color: #ff3838;
    color: #fff;
  }
  
  .status-partPayment {
    background-color: #feca57;
    color: #333;
  }
  
  .status-completed {
    background-color: #2196F3;
    color: #fff;
  }
  
  .status-default {
    background-color: #bdc3c7;
    color: #2c3e50;
  }
  
  /* Status Filter */
  .status-filter {
    padding: 12px;
    margin-left: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    font-size: 15px;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .status-filter:hover {
    background-color: #f9f9f9;
    transform: scale(1.05); /* Slight zoom effect */
  }
  