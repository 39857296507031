@import '../styles/Colors.css';

.dashboard-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.dashboard-content {
  display: flex;
  flex: 1;
  /* transition: all 0.1s ease-in-out; */
}
.main-content {
  flex: 1;
  padding: 0px;
  /* transition: all 0.1s ease-in-out; */
  /* margin-left: 80px; */
  font-family: var(--font-familt-nunito);
}
.dashboard-content.sidebar-open .main-content {
  margin-left: 250px;
  width: calc(100% - 250px);
}
.dashboard-title {
  background-color: var(--font-color);
  border-radius: 10px;
  padding: 10px;
}
.dashboard-title h1 {
  color: var(--background-color);
  margin-bottom: 0;
  font-size: 42px;
  line-height: 42px;
}
.dashboard-btn {
  background-color: var(--background-color);
  color: var(--btn-color);
}
.building-view {
  width: 20px;
}
